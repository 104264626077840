import React, {useEffect, useRef, useState} from 'react'
import { gsap } from "gsap";

export const ContactSVG = (props) => {

    const svgStyle = {
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: '2'
    }

    // {styleA} 
    const styleA = {
        fill: 'rgb(45,106,52)',
        fillRule: 'nonnzero'
    }

    //{styleB} 
    const styleB = {
        fill: 'rgb(239,162,38)',
        fillRule: 'nonzero'
    }



      return (
        <svg width="100%" height="100%" viewBox="0 0 1452 302" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsserif="http://www.serif.com/" style={svgStyle}>
            <g transform="matrix(1,0,0,1,-640,-642)">
                <g>
                    <g transform="matrix(1.01639,0,0,1.65383,168.762,-1004.83)">
                        <g transform="matrix(157.377,0,-64.8367,149.114,438.756,1097.02)">
                            <path d="M0.061,-0.667L0.061,-0.469L0.12,-0.469L0.12,-0.444C0.12,-0.405 0.104,-0.389 0.066,-0.389L0.04,-0.389L0.04,-0.291C0.061,-0.29 0.072,-0.29 0.091,-0.29C0.188,-0.29 0.217,-0.298 0.24,-0.33C0.258,-0.355 0.264,-0.39 0.264,-0.473L0.264,-0.667L0.061,-0.667ZM0.349,-0.667L0.349,-0.469L0.408,-0.469L0.408,-0.444C0.408,-0.405 0.392,-0.389 0.354,-0.389L0.328,-0.389L0.328,-0.291C0.349,-0.29 0.36,-0.29 0.379,-0.29C0.476,-0.29 0.505,-0.298 0.528,-0.33C0.546,-0.355 0.552,-0.39 0.552,-0.473L0.552,-0.667L0.349,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,533.969,1097.02)">
                            <path d="M0.047,-0L0.25,-0L0.25,-0.163L0.506,-0.163C0.65,-0.163 0.695,-0.167 0.74,-0.182C0.82,-0.211 0.847,-0.269 0.847,-0.417C0.847,-0.588 0.811,-0.642 0.685,-0.661C0.648,-0.666 0.63,-0.667 0.504,-0.667L0.047,-0.667L0.047,-0ZM0.25,-0.33L0.25,-0.5L0.504,-0.5C0.593,-0.499 0.595,-0.499 0.609,-0.494C0.635,-0.485 0.644,-0.465 0.644,-0.417C0.644,-0.371 0.638,-0.353 0.619,-0.341C0.602,-0.331 0.598,-0.331 0.504,-0.33L0.25,-0.33Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,670.414,1097.02)">
                            <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,784.198,1097.02)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.196C0.217,-0.269 0.222,-0.295 0.239,-0.318C0.256,-0.34 0.289,-0.352 0.329,-0.352C0.402,-0.352 0.422,-0.333 0.422,-0.263L0.589,-0.263L0.589,-0.295C0.589,-0.437 0.541,-0.485 0.398,-0.485C0.299,-0.485 0.249,-0.462 0.216,-0.402L0.207,-0.402L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,877.994,1097.02)">
                            <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,969.115,1097.02)">
                            <path d="M0,-0.346L0.091,-0.346L0.091,-0L0.268,-0L0.268,-0.346L0.442,-0.346L0.442,-0.473L0.268,-0.473C0.27,-0.547 0.287,-0.56 0.381,-0.56C0.392,-0.56 0.395,-0.56 0.428,-0.559L0.428,-0.676C0.39,-0.678 0.372,-0.679 0.339,-0.679C0.221,-0.679 0.176,-0.669 0.138,-0.634C0.102,-0.601 0.094,-0.574 0.091,-0.473L0,-0.473L0,-0.346Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1037.57,1097.02)">
                            <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1151.36,1097.02)">
                            <rect x="0.04" y="-0.667" width="0.177" height="0.667" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1191.8,1097.02)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1232.25,1097.02)">
                            <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1385.38,1097.02)">
                            <path d="M0.023,-0.221L0.023,-0.189C0.023,-0.097 0.038,-0.058 0.084,-0.029C0.131,0.001 0.212,0.01 0.419,0.01C0.618,0.01 0.702,0.005 0.742,-0.01C0.811,-0.036 0.839,-0.093 0.839,-0.209C0.839,-0.302 0.822,-0.347 0.776,-0.377C0.733,-0.404 0.69,-0.409 0.47,-0.416C0.329,-0.42 0.274,-0.423 0.256,-0.428C0.235,-0.433 0.226,-0.446 0.226,-0.471C0.226,-0.52 0.24,-0.524 0.412,-0.524C0.556,-0.524 0.581,-0.522 0.598,-0.512C0.613,-0.503 0.616,-0.494 0.618,-0.457L0.814,-0.457C0.815,-0.47 0.815,-0.484 0.815,-0.488C0.815,-0.567 0.799,-0.609 0.759,-0.636C0.712,-0.668 0.642,-0.677 0.448,-0.677C0.288,-0.677 0.196,-0.673 0.157,-0.665C0.065,-0.645 0.029,-0.588 0.029,-0.461C0.029,-0.314 0.075,-0.272 0.24,-0.267L0.294,-0.265L0.402,-0.26L0.562,-0.254C0.627,-0.253 0.642,-0.243 0.642,-0.203C0.642,-0.144 0.642,-0.144 0.424,-0.144C0.281,-0.144 0.259,-0.146 0.24,-0.158C0.224,-0.168 0.22,-0.181 0.22,-0.221L0.023,-0.221Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1521.19,1097.02)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1561.64,1097.02)">
                            <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1652.76,1097.02)">
                            <path d="M0.487,-0.151C0.48,-0.109 0.459,-0.101 0.346,-0.101C0.282,-0.101 0.245,-0.107 0.226,-0.121C0.209,-0.134 0.204,-0.15 0.204,-0.198L0.666,-0.198C0.667,-0.218 0.667,-0.24 0.667,-0.246C0.667,-0.353 0.651,-0.406 0.609,-0.441C0.566,-0.476 0.51,-0.486 0.35,-0.486C0.196,-0.486 0.137,-0.476 0.091,-0.444C0.041,-0.409 0.027,-0.362 0.027,-0.235C0.027,-0.114 0.041,-0.067 0.087,-0.032C0.13,-0 0.195,0.012 0.332,0.012C0.585,0.012 0.65,-0.02 0.663,-0.151L0.487,-0.151ZM0.204,-0.295C0.206,-0.361 0.234,-0.376 0.353,-0.376C0.423,-0.376 0.459,-0.369 0.475,-0.352C0.486,-0.341 0.488,-0.332 0.489,-0.295L0.204,-0.295Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,-64.8367,149.114,1761.82,1097.02)">
                            <path d="M0.061,-0.667L0.061,-0.469L0.12,-0.469L0.12,-0.444C0.12,-0.405 0.104,-0.389 0.066,-0.389L0.04,-0.389L0.04,-0.291C0.061,-0.29 0.072,-0.29 0.091,-0.29C0.188,-0.29 0.217,-0.298 0.24,-0.33C0.258,-0.355 0.264,-0.39 0.264,-0.473L0.264,-0.667L0.061,-0.667ZM0.349,-0.667L0.349,-0.469L0.408,-0.469L0.408,-0.444C0.408,-0.405 0.392,-0.389 0.354,-0.389L0.328,-0.389L0.328,-0.291C0.349,-0.29 0.36,-0.29 0.379,-0.29C0.476,-0.29 0.505,-0.298 0.528,-0.33C0.546,-0.355 0.552,-0.39 0.552,-0.473L0.552,-0.667L0.349,-0.667Z" style={styleA} />
                        </g>
                    </g>
                    <g transform="matrix(0.43965,0,0,0.71538,822.097,157.777)">
                        <g transform="matrix(157.377,0,0,149.114,438.756,1097.02)">
                            <path d="M0.68,-0.257C0.679,-0.158 0.676,-0.157 0.453,-0.157C0.239,-0.157 0.236,-0.159 0.236,-0.33C0.236,-0.431 0.244,-0.47 0.268,-0.49C0.288,-0.506 0.325,-0.51 0.452,-0.51C0.58,-0.51 0.639,-0.504 0.654,-0.491C0.666,-0.481 0.668,-0.472 0.67,-0.428L0.873,-0.428L0.873,-0.461C0.873,-0.568 0.853,-0.617 0.796,-0.646C0.75,-0.67 0.688,-0.677 0.51,-0.677C0.161,-0.677 0.088,-0.66 0.052,-0.57C0.037,-0.533 0.033,-0.478 0.033,-0.329C0.033,-0.244 0.035,-0.189 0.038,-0.159C0.046,-0.091 0.066,-0.054 0.11,-0.029C0.164,0.002 0.248,0.01 0.509,0.01C0.657,0.01 0.73,0.004 0.784,-0.013C0.858,-0.037 0.886,-0.086 0.886,-0.194C0.886,-0.204 0.885,-0.219 0.884,-0.257L0.68,-0.257Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,581.811,1097.02)">
                            <path d="M0.362,-0.485C0.201,-0.485 0.132,-0.474 0.088,-0.442C0.041,-0.408 0.027,-0.363 0.027,-0.252C0.027,-0.115 0.038,-0.071 0.082,-0.035C0.127,0.002 0.186,0.012 0.363,0.012C0.538,0.012 0.597,0.002 0.642,-0.035C0.685,-0.071 0.697,-0.115 0.697,-0.245C0.697,-0.364 0.684,-0.407 0.636,-0.442C0.592,-0.474 0.524,-0.485 0.362,-0.485ZM0.363,-0.352C0.413,-0.352 0.455,-0.347 0.477,-0.338C0.51,-0.325 0.52,-0.302 0.52,-0.238C0.52,-0.138 0.497,-0.121 0.363,-0.121C0.306,-0.121 0.266,-0.126 0.244,-0.136C0.212,-0.149 0.204,-0.17 0.204,-0.233C0.204,-0.334 0.228,-0.352 0.363,-0.352Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,695.595,1097.02)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,808.748,1097.02)">
                            <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,899.869,1097.02)">
                            <path d="M0.221,-0.325C0.226,-0.368 0.239,-0.372 0.366,-0.372C0.473,-0.372 0.487,-0.362 0.487,-0.288L0.487,-0.263L0.479,-0.263C0.451,-0.293 0.427,-0.3 0.345,-0.3C0.215,-0.3 0.169,-0.297 0.126,-0.285C0.057,-0.267 0.025,-0.22 0.025,-0.135C0.025,-0.023 0.074,0.012 0.231,0.012C0.346,0.012 0.391,0.008 0.42,-0.004C0.452,-0.017 0.471,-0.034 0.486,-0.065L0.493,-0.065L0.493,-0L0.663,-0L0.663,-0.285C0.663,-0.371 0.653,-0.407 0.623,-0.439C0.583,-0.479 0.547,-0.486 0.392,-0.486C0.25,-0.486 0.168,-0.477 0.127,-0.458C0.072,-0.431 0.049,-0.394 0.044,-0.325L0.221,-0.325ZM0.339,-0.2C0.414,-0.2 0.442,-0.199 0.453,-0.195C0.473,-0.188 0.484,-0.174 0.484,-0.155C0.484,-0.113 0.449,-0.101 0.324,-0.101C0.229,-0.101 0.202,-0.112 0.202,-0.15C0.202,-0.189 0.231,-0.2 0.339,-0.2Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1010.03,1097.02)">
                            <path d="M0.494,-0.19C0.486,-0.136 0.456,-0.122 0.352,-0.122C0.305,-0.122 0.267,-0.127 0.246,-0.136C0.214,-0.149 0.204,-0.173 0.204,-0.235C0.204,-0.334 0.227,-0.353 0.35,-0.353C0.454,-0.353 0.489,-0.335 0.495,-0.281L0.675,-0.281C0.672,-0.37 0.659,-0.407 0.621,-0.437C0.576,-0.474 0.507,-0.486 0.35,-0.486C0.19,-0.486 0.128,-0.475 0.083,-0.438C0.041,-0.404 0.027,-0.354 0.027,-0.24C0.027,-0.115 0.042,-0.064 0.088,-0.032C0.135,0.002 0.196,0.012 0.341,0.012C0.527,0.012 0.585,-0 0.63,-0.046C0.661,-0.077 0.671,-0.111 0.674,-0.19L0.494,-0.19Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1120.04,1097.02)">
                            <path d="M0.093,-0.582L0.093,-0.473L0,-0.473L0,-0.346L0.093,-0.346L0.093,-0.208C0.093,-0.169 0.093,-0.145 0.094,-0.137C0.096,-0.083 0.106,-0.057 0.131,-0.032C0.166,0.002 0.212,0.012 0.325,0.012C0.447,0.012 0.501,-0.003 0.537,-0.048C0.564,-0.083 0.571,-0.112 0.573,-0.202L0.411,-0.202C0.411,-0.153 0.41,-0.149 0.404,-0.138C0.396,-0.121 0.376,-0.114 0.34,-0.114C0.306,-0.114 0.288,-0.121 0.279,-0.137C0.271,-0.149 0.27,-0.156 0.27,-0.208L0.27,-0.346L0.55,-0.346L0.55,-0.473L0.27,-0.473L0.27,-0.582L0.093,-0.582Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1211.16,1097.02)">
                            <path d="M0.025,-0.151C0.026,-0.096 0.038,-0.063 0.067,-0.037C0.109,0.002 0.168,0.012 0.34,0.012C0.614,0.012 0.678,-0.019 0.678,-0.154C0.678,-0.26 0.626,-0.3 0.489,-0.301C0.307,-0.302 0.276,-0.303 0.247,-0.305C0.219,-0.307 0.205,-0.317 0.205,-0.336C0.205,-0.366 0.239,-0.375 0.35,-0.375C0.41,-0.375 0.446,-0.371 0.461,-0.361C0.467,-0.357 0.469,-0.354 0.473,-0.339L0.653,-0.339C0.649,-0.396 0.64,-0.421 0.615,-0.441C0.575,-0.475 0.516,-0.485 0.355,-0.485C0.231,-0.485 0.148,-0.478 0.11,-0.463C0.054,-0.442 0.028,-0.398 0.028,-0.325C0.028,-0.224 0.084,-0.182 0.218,-0.18C0.392,-0.18 0.436,-0.179 0.46,-0.177C0.486,-0.174 0.501,-0.161 0.501,-0.142C0.501,-0.108 0.468,-0.098 0.353,-0.098C0.242,-0.098 0.208,-0.11 0.205,-0.151L0.025,-0.151Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1361.14,1097.02)">
                            <path d="M0.669,-0.365C0.671,-0.353 0.671,-0.345 0.671,-0.335L0.671,-0.32L0.669,-0.288L0.668,-0.28L0.33,-0.445C0.294,-0.462 0.289,-0.468 0.289,-0.489C0.289,-0.507 0.295,-0.517 0.308,-0.522C0.318,-0.526 0.362,-0.528 0.457,-0.528C0.543,-0.528 0.577,-0.525 0.587,-0.516C0.595,-0.509 0.597,-0.497 0.599,-0.455L0.777,-0.455L0.778,-0.494C0.778,-0.582 0.765,-0.621 0.725,-0.646C0.686,-0.67 0.642,-0.674 0.418,-0.674C0.279,-0.674 0.227,-0.671 0.192,-0.661C0.126,-0.642 0.1,-0.601 0.1,-0.514C0.1,-0.429 0.115,-0.397 0.165,-0.377C0.053,-0.361 0.027,-0.329 0.027,-0.206C0.027,-0.108 0.043,-0.063 0.088,-0.032C0.134,-0.001 0.203,0.007 0.421,0.007C0.668,0.007 0.742,-0.008 0.784,-0.068L0.923,0.004L0.987,-0.126L0.832,-0.203C0.839,-0.228 0.841,-0.248 0.841,-0.325C0.841,-0.344 0.841,-0.349 0.84,-0.365L0.669,-0.365ZM0.621,-0.148C0.607,-0.142 0.607,-0.142 0.486,-0.141C0.412,-0.14 0.412,-0.14 0.4,-0.14C0.225,-0.14 0.216,-0.144 0.216,-0.217C0.216,-0.287 0.222,-0.295 0.284,-0.301L0.621,-0.148Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1549.52,1097.02)">
                            <path d="M0.047,-0.667L0.047,-0L0.734,-0L0.734,-0.17L0.26,-0.17L0.26,-0.667L0.047,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1665.03,1097.02)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.473L0.04,-0.473ZM0.04,-0.667L0.04,-0.534L0.217,-0.534L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1705.48,1097.02)">
                            <path d="M0.04,-0.473L0.04,-0L0.217,-0L0.217,-0.189C0.217,-0.276 0.221,-0.298 0.243,-0.321C0.264,-0.343 0.299,-0.352 0.364,-0.352C0.43,-0.352 0.464,-0.345 0.483,-0.326C0.502,-0.308 0.505,-0.291 0.505,-0.215L0.505,-0L0.682,-0L0.682,-0.215C0.682,-0.345 0.673,-0.381 0.63,-0.426C0.588,-0.469 0.53,-0.485 0.417,-0.485C0.302,-0.485 0.248,-0.462 0.216,-0.401L0.207,-0.401L0.207,-0.473L0.04,-0.473Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1818.63,1097.02)">
                            <path d="M0.04,-0.667L0.04,-0L0.217,-0L0.217,-0.19L0.262,-0.19L0.452,-0L0.687,-0L0.409,-0.253L0.654,-0.473L0.417,-0.473L0.255,-0.319L0.217,-0.319L0.217,-0.667L0.04,-0.667Z" style={styleA} />
                        </g>
                        <g transform="matrix(157.377,0,0,149.114,1923.6,1097.02)">
                            <path d="M0.025,-0.151C0.026,-0.096 0.038,-0.063 0.067,-0.037C0.109,0.002 0.168,0.012 0.34,0.012C0.614,0.012 0.678,-0.019 0.678,-0.154C0.678,-0.26 0.626,-0.3 0.489,-0.301C0.307,-0.302 0.276,-0.303 0.247,-0.305C0.219,-0.307 0.205,-0.317 0.205,-0.336C0.205,-0.366 0.239,-0.375 0.35,-0.375C0.41,-0.375 0.446,-0.371 0.461,-0.361C0.467,-0.357 0.469,-0.354 0.473,-0.339L0.653,-0.339C0.649,-0.396 0.64,-0.421 0.615,-0.441C0.575,-0.475 0.516,-0.485 0.355,-0.485C0.231,-0.485 0.148,-0.478 0.11,-0.463C0.054,-0.442 0.028,-0.398 0.028,-0.325C0.028,-0.224 0.084,-0.182 0.218,-0.18C0.392,-0.18 0.436,-0.179 0.46,-0.177C0.486,-0.174 0.501,-0.161 0.501,-0.142C0.501,-0.108 0.468,-0.098 0.353,-0.098C0.242,-0.098 0.208,-0.11 0.205,-0.151L0.025,-0.151Z" style={styleA} />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}
