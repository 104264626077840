import React, {useEffect, useRef} from 'react'
import {settings} from '../storage'
import Menu from '../modules/menu'
import {ContactSVG} from '../svg/contact'
import { gsap } from "gsap";
import { navigate } from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

export default function Contact() {

  let link1 = useRef(null)
  let link2 = useRef(null)
  let link3 = useRef(null)
  let link4 = useRef(null)
  let link5 = useRef(null)
  let link6 = useRef(null)
  let link7 = useRef(null)
  let link8 = useRef(null)

  useEffect(() => {
    settings.color = '#000000'
  })

  const textHover = (idx, direction) => {

    let hasEntered = direction
    let links = [link1, link2, link3, link4, link5, link6, link7, link8]
    let link = links[idx]

    if (hasEntered){
        gsap.to(link, 0.5, {ease: 'Power4.easeOut', color: '#D62732'})
    }
    else {
        gsap.to(link, 0.5, {ease: 'Power4.easeOut', color: '#000000'})
    }

}


  return (
    <div className="app">
      <Menu static={true} page="contact" />
      <div id="contact" className="page_parent_container">
        <div id="contact_bg" className="page_body_container">
        {/* <div id="contact_header"> */}
        <div id='contact_svg_container' className="page_header">
          <ContactSVG />
          <br />
        </div>


        <div id="contact_content">
          <div>
          <h2 id="rpm">33 RPM</h2>
          <h3>SIDE A</h3>
            <br />
            <h1 onMouseEnter={() => textHover(0, true)} onMouseLeave={() => textHover(0, false)} className="contact_link"><a ref={el => {link1 = el}} href="mailto:gnhbuchanan@gmail.com">1. Digital Letters <FontAwesomeIcon icon={faEnvelope} /></a></h1>
            <br />
            <h1 ref={el => {link2 = el}} onClick={() => {window.open('https://www.linkedin.com/in/gustaf-buchanan/', '_blank')}} onMouseEnter={() => textHover(1, true)} onMouseLeave={() => textHover(1, false)} className="contact_link">2. Workin' Like a Dog <FontAwesomeIcon icon={faLinkedin} /></h1>
            <br />
            <h1 ref={el => {link3 = el}} onClick={() => {window.open('https://github.com/gnhbuchanan', '_blank')}} onMouseEnter={() => textHover(2, true)} onMouseLeave={() => textHover(2, false)} className="contact_link">3. Save It For a Rainy Day <FontAwesomeIcon icon={faGithub} /></h1>
            <br />
            <h1 ref={el => {link4 = el}} onClick={() => {window.open('https://www.chartexplorer.us', '_blank')}} onMouseEnter={() => textHover(3, true)} onMouseLeave={() => textHover(3, false)} className="contact_link">4. Chart Explorer</h1>

            <h3 id="sideB">SIDE B</h3>
            <br />
            <h1 ref={el => {link5 = el}} onClick={() => {navigate('/about')}} onMouseEnter={() => textHover(4, true)} onMouseLeave={() => textHover(4, false)}>5. Woudn't You Like To Know Me Better?</h1>
            <br />
            <h1 ref={el => {link6 = el}} onClick={() => {navigate('/work')}} onMouseEnter={() => textHover(5, true)} onMouseLeave={() => textHover(5, false)}>6. Labors of Love</h1>
            <br />
            <h1 ref={el => {link7 = el}} onClick={() => {navigate('/contact')}} onMouseEnter={() => textHover(6, true)} onMouseLeave={() => textHover(6, false)}>7. Don't Forget To Write</h1>
            <br />
            <h1 ref={el => {link8 = el}} onClick={() => {navigate('/')}} onMouseEnter={() => textHover(7, true)} onMouseLeave={() => textHover(7, false)}>8. Away Is Good, But Home Is Best</h1>
            <h5 id="contact_credits">"Portfolio Site" was created in Chicago, IL and mastered in San Diego, CA</h5>
            <h5>Copyright © 2021 Gustaf Buchanan</h5>
            <h5>www.gustafbuchanan.com</h5>
          </div>
        </div>

        </div>
      </div>
    </div>
  )
}
